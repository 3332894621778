<template>
  <div class="medical-detail">
    <el-form>
      <el-form-item label="患者姓名:">
        {{ detailInfo.patientName }}
      </el-form-item>
      <el-form-item label="手机号码:">
        {{ detailInfo.patientMobile }}
      </el-form-item>
      <el-form-item label="上传ID:">
        {{ detailInfo.id }}
      </el-form-item>
      <el-form-item label="上传时间:">
        {{ detailInfo.createdDate }}
      </el-form-item>
      <h2>{{ medicalAuditStateDic[detailInfo.status] }}</h2>
      <el-form-item label="共计:"> {{ imgSize }}张 </el-form-item>
      <div class="imgStyle">
        <!-- <el-carousel>
          <el-carousel-item
            v-for="item in detailInfo.imageList"
            :key="item.id"
          >
            <el-image
              :src="item.imgUrl"
              class="img"
              :preview-src-list="detailInfo.imageList"
            />
          </el-carousel-item>
        </el-carousel> -->
        <el-image
          v-if="imgSize"
          :src="detailInfo.imageList[0].imgUrl"
          class="img"
          fit="scale-down"
          :preview-src-list="detailInfo.imgList"
        />
      </div>
    </el-form>
    <div>
      <el-select
        v-if="detailInfo.status !== 2"
        v-model="historyType"
        placeholder="请选择病史类"
        :disabled="detailInfo.status === 0"
      >
        <el-option
          v-for="item in medicalTypeList"
          :key="item.value"
          :value="item.value"
          :label="item.text"
        />
      </el-select>
      <el-select
        v-model="type"
        placeholder="请选择报告类"
        :disabled="detailInfo.status !== 5"
      >
        <el-option
          v-for="item in reportTypeList"
          :key="item.value"
          :value="item.value"
          :label="item.text"
        />
      </el-select>
      <el-button
        type="primary"
        :disabled="detailInfo.status !== 5 || type === '' || historyType === ''"
        @click="jumpDialog('FAIL')"
      >
        驳回
      </el-button>
      <el-button
        type="primary"
        :disabled="detailInfo.status !== 5 || type === '' || historyType === ''"
        @click="jumpDialog('SUCCESS')"
      >
        审核通过
      </el-button>
      <el-button
        type="primary"
        :disabled="noMedicalReport"
        @click="getNextMedicalData"
      >
        下份报告
      </el-button>
      <el-button type="primary" @click="$router.back()"> 返回 </el-button>
    </div>
    <el-dialog :title="titleType" :visible.sync="showDialog" width="35%">
      <h3
        v-if="titleType === '审核通过'"
        style="margin-bottom: 20px; text-align: center"
      >
        确定当前报告通过审核吗？
      </h3>
      <el-form
        v-if="showDialog && titleType === '驳回'"
        ref="form"
        label-position="right"
        :model="form"
        :rules="rules"
        label-width="160px"
        @submit.native.prevent
      >
        <el-form-item label="驳回原因:" prop="remark">
          <el-select v-model="form.remark" placeholder="请选择驳回原因">
            <el-option
              v-for="item in failReasonList"
              :key="item.value"
              :value="item.text"
              :label="item.text"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="audit(titleType)">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { medicalAuditStateDic } from '@/utils/component/publicList.js';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
export default {
  name: 'MedicalDetail',
  data() {
    this.medicalTypeList = [
      { text: 'PCI', value: 1 },
      { text: '非PCI', value: 2 },
    ];
    this.reportTypeList = [
      { text: '出院小结', value: 1 },
      { text: '检验报告', value: 3 },
      { text: '检查报告', value: 4 },
      { text: '其他', value: 9 },
    ];
    this.failReasonList = [
      { text: '比较模糊', value: 1 },
      { text: '存在缺页', value: 2 },
      { text: '不是出院小结/复查检验记录', value: 3 },
    ];
    return {
      medicalAuditStateDic: medicalAuditStateDic, // 字典
      type: '',
      historyType: '',
      detailInfo: {}, // 详情信息
      titleType: '', // 弹框标题
      showDialog: false, // 弹框默认不展示
      noMedicalReport: false, // 下一份报告按钮是否禁用
      medicalIdList: [], // 查询接口返回的id列表
      currentIndex: '',
      form: {
        remark: '',
      },
      rules: {
        remark: [
          { required: true, message: '请选择驳回原因', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapState('medicalHistoryManage', {
      medicalHistoryList: (state) => state.medicalHistoryList,
    }),

    currentId() {
      return this.$route.query.id;
    },
    listParams() {
      return JSON.parse(this.$route.query.params) || {};
    },
    // 图片的张数
    imgSize() {
      const {
        detailInfo: { imgList },
      } = this;
      if (imgList) {
        return imgList.length;
      }
      return 0;
    },
  },
  async mounted() {
    await this.getMedicalReportIdList();
    this.getMedicalDetail(this.currentId);
  },
  methods: {
    ...mapActions('medicalHistoryManage', ['medicalPageQuery']),
    getMedicalDetail(id) {
      this.$api.getInfo({ id: id }).then((res) => {
        if (res.code === 0) {
          this.type = res.data?.type;
          this.historyType = res.data?.historyType;
          this.detailInfo = res.data;
          console.log(this.detailInfo, 'this.detailInfo');

          this.detailInfo.imgList = [];
          const { imageList } = res.data;
          if (imageList && Array.isArray(imageList)) {
            this.detailInfo.imgList = imageList.map(
              ({ imgUrl }) => imgUrl || ''
            );
          }
          console.log(
            this.imgSize,
            this.detailInfo.imgList,
            'this.medicalIdList.length',
            this.medicalIdList.length,
            'this.currentIndex',
            this.currentIndex,
            this.medicalIdList.length <= this.currentIndex + 1,
            '11'
          );
          if (this.medicalIdList.length <= this.currentIndex + 1) {
            this.noMedicalReport = true;
          }
        }
      });
    },
    // 查看下一份报告
    getNextMedicalData() {
      const { medicalIdList, currentIndex } = this;
      console.log(
        medicalIdList[currentIndex + 1],
        'medicalIdList[currentIndex + 1]'
      );
      this.getMedicalDetail(medicalIdList[currentIndex + 1]);
      this.currentIndex += 1;
    },
    // 查询报告id列表
    getMedicalReportIdList() {
      this.currentIndex = this.$route.query.index;
      const { listParams } = this;
      const param = {
        ...listParams,
        startDate:
          listParams.time !== '' && listParams.time !== null
            ? moment(listParams.time[0]).format('YYYY-MM-DD')
            : '',
        endDate:
          listParams.time !== '' && listParams.time !== null
            ? moment(listParams.time[1]).format('YYYY-MM-DD')
            : '',
      };
      this.$api.queryMedicalRecordIds(param).then((res) => {
        if (res.code === 0) {
          if (res.data.length === 0 || !res.data) {
            this.noMedicalReport = true;
          } else {
            this.medicalIdList = res.data;
          }
        }
      });
    },
    // 跳转至弹框
    jumpDialog(type) {
      this.titleType = type === 'SUCCESS' ? '审核通过' : '驳回';
      this.showDialog = true;
    },
    // 点击弹框确定
    audit(titleType) {
      const { currentId, form, type, historyType } = this;
      const params = {
        id: currentId,
        remark: titleType === '驳回' ? form.remark : '',
        status: titleType === '驳回' ? 2 : 0,
        type: type,
        historyType: historyType,
      };
      if (titleType === '驳回') {
        this.$refs['form'].validate((valid) => {
          if (!valid) {
            return false;
          }
        });
      }
      this.$api.auditInfo(params).then((res) => {
        if (res.code) {
          this.showDialog = false;
          this.$message.success(
            titleType === '驳回' ? '驳回成功' : '审核通过成功'
          );
          this.form.remark = '';
          this.getMedicalDetail(this.currentId);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}
.imgStyle {
  margin-bottom: 20px;
  .img {
    height: 300px;
    width: 300px;
  }
}
</style>
